<template>
  <Mymodal
    :show="show"
    @close="close"
    @sure="close"
  >
    <div class="title tit2">恭喜您，获得以下奖励！</div>
    <!--<div class="txt">
       道具将24小时内发送到新区绑定角色的邮箱内，请注意查收
    </div> -->
    <div class="list">
      <div class="single" v-for="item in prizecon">
        <div class="img-info">
          <img  :src="`https://wcdn.tomatogames.com/web/guonei/sezg/prize-icon/${item.img}.png`"  class="g-img">
          <div class="num">X{{item.num}}</div>
        </div>
        <div class="expr">{{item.name}}</div>
      </div>
    </div>
    <div class="sure" @click="close">确认</div>
    
  </Mymodal>
</template>
<script>
  import { mapState } from "vuex"
 
  export default {
    name: '',
    computed:{
      ...mapState({
        show:(state)=>state.dialog === 'success',
        prizecon:(state)=>{
          return state.prizecon
        }
      })
    },
    data(){
      return {
      }
    },
    methods:{
      close(){
        this.$store.commit('SETVAL',{dialog:''})
      }
    },
    mounted(){
    },
  }
</script>
<style scoped lang='less'>
.txt {
  width: 531px;
  height: 61px;
  font-size: 26px;
  color: #333333;
  line-height: 36px;
  margin:0 auto;
  text-align: center;
}
.list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width:650px;
  margin: 0 auto;
  padding-top:50px;
  .single {
    flex : 0 0 33%;
    margin-bottom: 20px;
    .img-info{
      width: 157px;
      height: 157px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to bottom,#ffe1b7 40%,#fff);
      border-radius: 50%;
      position: relative;
      margin: 20px auto;
      .g-img{
        width: 125px;
      }
      .num {
        position: absolute;
        line-height: 37px;
        .set-bg(93px,37px,'ricon.png');
        color:#fff;
        top: 0px;
        right: -30px;
        font-size: 24px;
        box-sizing: border-box;
        padding-right: 20px;
        text-align: center;
      }
    }
    .expr {
      font-size: 24px;
      color: #999999;
      text-align: center;
    }
  }
}
.sure {
  .set-bg(562px,105px,'s-btn-bg.png');
  font-size: 54px;
  color: #FFFFFF;
  line-height: 105px;
  text-align: center;
  margin: 50px auto 0 auto;
}
</style>